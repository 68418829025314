<template>
  <v-container fluid>
    <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mx-4' : ''">
      <v-col>
        <v-card class="pa-5">
          <v-card-title>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar..."
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="text-right align-center">
                <v-btn color="primary" @click="openNew">Nuevo Usuario</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table :headers="headers" :items="items" :search="search">
            <template v-slot:item.ventas="{ item }">
              <v-btn icon color="primary" @click="editUser(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            {{ dialogTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
          <v-form @submit.prevent="handleSubmit(saveUser)">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Usuario"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.username"
                      label="Usuario"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Correo"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nombre"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.name"
                      label="Nombre"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nombre"
                    rules="required"
                  >
                    <v-select
                      v-model="user.permissions"
                      multiple
                      :items="permissions"
                      label="Permisos"
                      item-text="title"
                      item-value="name"
                      :error-messages="errors"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!newUser" cols="12">
                  <v-switch
                    v-model="user.update_password"
                    label="Actualizar contraseña"
                  ></v-switch
                ></v-col>
                <v-col v-if="newUser || user.update_password" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Contraseña"
                    rules="required|confirmed:password"
                  >
                    <v-text-field
                      v-model="user.password"
                      label="Contraseña"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col v-if="newUser || user.update_password" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Confirmar Contraseña"
                    rules="required"
                    vid="password"
                  >
                    <v-text-field
                      v-model="user.confirm_password"
                      label="Confirmar"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <!--            <v-tabs-->
            <!--              v-model="tab"-->
            <!--              centered-->
            <!--              dark-->
            <!--              icons-and-text-->
            <!--              grow-->
            <!--              background-color="primary darken-1"-->
            <!--              slider-color="primary lighten-3"-->
            <!--              slider-size="5"-->
            <!--            >-->
            <!--              <v-tab>-->
            <!--                Datos-->
            <!--                <v-icon>mdi-badge-account-horizontal</v-icon>-->
            <!--              </v-tab>-->
            <!--              <v-tab>-->
            <!--                Permisos-->
            <!--                <v-icon>mdi-list-status</v-icon>-->
            <!--              </v-tab>-->
            <!--              <v-tab-item>-->
            <!--                -->
            <!--              </v-tab-item>-->
            <!--              <v-tab-item>-->
            <!--                <v-card-text>-->
            <!--                  <h3>Permisos</h3>-->
            <!--                </v-card-text>-->
            <!--              </v-tab-item>-->
            <!--            </v-tabs>-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="dialog = false">
                Cerrar
              </v-btn>
              <v-btn color="primary darken-1" type="submit" :disabled="invalid">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import menu from "@/utils/menu";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { email, max, required, confirmed } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacio.",
});
extend("email", {
  ...email,
  message: "Debe ser un correo valido.",
});
extend("max", {
  ...max,
  message: "{_field_} no puede ser mas de {length} caracteres.",
});

extend("confirmed", {
  ...confirmed,
  message: "Los campos no coinciden.",
});

export default {
  name: "Users",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    menus: menu,
    tab: 0,
    dialog: false,
    search: "",
    headers: [
      {
        text: "#",
        align: "center",
        value: "id",
        class: "white--text primary",
      },
      {
        text: "Nombre",
        align: "center",
        value: "name",
        class: "white--text primary",
      },
      {
        text: "Usuario",
        align: "center",
        value: "username",
        class: "white--text primary",
      },
      {
        text: "",
        align: "right",
        value: "ventas",
        class: "white--text primary",
      },
    ],
    items: [],
    permissions: menu,
    user: {
      username: null,
      name: null,
      email: null,
      password: null,
      confirm_password: null,
      update_password: false,
      permissions: [],
    },
    defaultUser: {
      username: null,
      name: null,
      email: null,
      password: null,
      confirm_password: null,
      update_password: false,
      permissions: [],
    },
    newUser: true,
  }),
  computed: {
    dialogTitle() {
      return this.newUser ? "Nuevo Usuario" : "Actualizar Usuario";
    },
  },
  watch: {
    dialog(val) {
      val || this.closeDialog();
    },
  },
  mounted() {
    this.getUsers();
    // this.getPermisions();
  },
  methods: {
    getUsers() {
      this.$store.dispatch("working", true);
      this.$http
        .get("user/getUsers")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
    getPermisions() {},
    openNew() {
      this.newUser = true;
      this.dialog = true;
    },
    editUser(item) {
      this.user = JSON.parse(JSON.stringify(item));
      this.newUser = false;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;

      this.$nextTick(() => {
        this.newUser = true;
        this.user = JSON.parse(JSON.stringify(this.defaultUser));
        this.$refs.observer.reset();
      });
    },
    saveUser() {
      let url = this.newUser ? "create" : "update";
      console.log("sdldldlkd")
      console.log(this.user)
      this.$store.dispatch("working", true);
      this.$http
        .post("user/" + url, this.user)
        .then(() => {
          this.getUsers();
          this.closeDialog();
        })
        .catch(function (error) {
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

  })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped></style>
